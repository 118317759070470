import React from "react";
import {
  BellOutlined,
  QuestionCircleOutlined,
  UserOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { Avatar } from "antd";
import { NavLink } from "react-router-dom";
import { ReactComponent as Info } from "../../../assets/info.svg";

/**
 * @function core#templates/menu/menuTemplate
 * @param {function} t
 * @param {any} user
 * @param {function} goToProfil
 * @param {function} logout
 * @returns {object} object
 */
const menuTemplate = (
  t: Function,
  user: any = null,
  goToProfil: Function,
  logout: Function
) => {
  const menuItems: any = [
    {
      label: (
        <Avatar size={40} style={{ marginTop: "-5px", color: "#003a6c" }}>
          {user?.firstName?.charAt(0) + user?.lastName?.charAt(0)}
        </Avatar>
      ),
      key: "submenu1",
      theme: "dark",
      className: "right navbar-item",
      children: [
        {
          label: t("coreNav:profil"),
          icon: <UserOutlined style={{ color: "#fff" }} />,
          key: "submenu-item-1",
          onClick: () => goToProfil(),
        },
        {
          label: t("coreNav:logout"),
          icon: <LogoutOutlined style={{ color: "#fff" }} />,
          key: "submenu-item-2",
          onClick: () => logout(),
        },
      ],
    },
    {
      label: "",
      key: "submenu2",
      theme: "dark",
      icon: <QuestionCircleOutlined />,
      children: [
        {
          label: <NavLink to="/about">{t("coreNav:about")}</NavLink>,
          icon: <Info />,
          theme: "dark",
          key: "submenu2-item-1",
        },
        {
          label: <NavLink to="/imprint">{t("coreNav:imprint")}</NavLink>,
          icon: <Info />,
          key: "submenu2-item-2",
        },
      ],
    },
  ];

  return menuItems;
};

export default menuTemplate;
