import React, { FC } from "react";
import { Card, Layout, Skeleton } from "antd";
import { observer } from "mobx-react";
import { useTranslation, withTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ICaseDetailSidebar } from "../../models/components/ICaseDetailSidebar";
import PatientBodyDetails from "../PatientBodyDetails/PatientBodyDetails";
import TherapyDetails from "../TherapyDetails/TherapyDetails";
import CaseTreatment from "../CaseTreatment/CaseTreatment";
import CaseCardPatient from "../CaseCardPatient/CaseCardPatient";

const { Content } = Layout;

const CaseDetailSidebar: FC<ICaseDetailSidebar> = ({
  organisationManagementStore,
  medicationTherapyStore,
  patientCareStore,
  userAttributes,
  showSkeleton,
}): JSX.Element => {
  const { t } = useTranslation();
  const { patientId } = useParams();

  const orgConfig = organisationManagementStore.getOrgConfig;
  // current medplan details
  const currentMedplan = medicationTherapyStore.getCurrentMedplan;
  return (
    <Content className="dtb-sidebar dtb-case-sidebar">
      <div className="dtb-scroll-content">
        {orgConfig !== null && userAttributes.isPlanner && (
          <a
            href={orgConfig?.planerPatientPage.replace(
              "{patientId}",
              "" + patientId
            )}
            type="primary"
            style={{
              margin: "0px 0px 14px 0px",
              width: "100%",
              boxSizing: "border-box",
            }}
            className="ant-btn ant-btn-primary"
          >
            {t("caseSidebar:patientPlaner")}
          </a>
        )}

        <CaseCardPatient
          patientCareStore={patientCareStore}
          showSkeleton={showSkeleton}
          size={"small"}
        />

        <CaseTreatment
          patientCareStore={patientCareStore}
          showSkeleton={showSkeleton}
          size={"small"}
        />
        {/*
        <Card size="small" title="Diagnosen" id="dtb-sidebar-diagnose">
          {minTimeLoader ? (
            <>
              <Skeleton
                title={false}
                paragraph={{ rows: 2 }}
                style={{ margin: 0, padding: 0 }}
              />
            </>
          ) : (
            <>
              {therapyStore.diagnoses?.[0] !== undefined ? (
                <>
                  <Tag>{therapyStore.diagnoses?.[0]?.icd10Id}</Tag>
                  <Text>{therapyStore.diagnoses?.[0]?.title}</Text>
                </>
              ) : (
                <p style={{ textAlign: 'center' }}>Keine Daten vorhanden</p>
              )}
            </>
          )}
        </Card>
        */}
        <Card
          size="small"
          title={t("caseSidebar:therapyPlan")}
          id="dtb-sidebar-therapyplan"
        >
          {showSkeleton ? (
            <Skeleton
              title={false}
              paragraph={{ rows: 5 }}
              style={{ margin: 0, padding: 0 }}
            />
          ) : (
            <TherapyDetails
              medicationTherapyStore={medicationTherapyStore}
              currentMedplan={currentMedplan}
            />
          )}
        </Card>
        <Card
          size="small"
          title={t("caseSidebar:patientInformation")}
          id="dtb-sidebar-patientinfo"
        >
          {showSkeleton ? (
            <>
              <Skeleton
                title={false}
                paragraph={{ rows: 3 }}
                style={{ margin: 0, padding: 0, marginTop: 10 }}
              />
            </>
          ) : (
            <PatientBodyDetails medplan={currentMedplan} />
          )}
        </Card>
      </div>
    </Content>
  );
};

export default withTranslation("patient")(observer(CaseDetailSidebar));
