import { Col, Row, Typography } from "antd";
import React, { FC } from "react";
import { IImprint } from "../../models/pages/IImprint";
import "./../../scss/pages/imprint.scss";

const { Title, Text } = Typography;

/**
 * @property {module:core} core
 * @class core.pages/Imprint
 * @description inprint page
 * @type {React.FC<IImprint>}
 * @return {React.ReactElement}
 */
const Imprint: FC<IImprint> = (): JSX.Element => {
  return (
    <div
      className="dtb-content-inner-scroll-container"
      style={{
        paddingTop: 14,
        paddingBottom: 14,
        marginLeft: 14,
        marginRight: 14,
        overflowY: "auto",
        display: "flex",
        minHeight: "100%",
      }}
    >
      <Row justify="center">
        <br />
        <Col xs={24} sm={24} xl={24} className="about-wrapper">
          <Title level={2} className="text-center ">
            Impressum
          </Title>

          <Col className="content-wrapper dtb-long-text dtb-imprint-text-layout">
            <div className="avoid-break">
              <Text strong>
                DTB Gesellschaft für digitale Therapiebegleitung mbH
                <br />
              </Text>
              <Text>
                Otto-Schott-Straße 15 <br />
                07745 Jena <br /> <br />
              </Text>
            </div>
            <div className="avoid-break">
              <Text strong>
                Geschäftsführer: <br />
              </Text>
              <Text>
                Ingmar Wegner <br />
                <br />
                Telefon: 03641 62840284 <br />
                Telefax: 03641 628400320 <br />
                E-Mail:{" "}
                <a href="mailto:info@digitale-therapiebegleitung.de">
                  info@digitale-therapiebegleitung.de
                </a>{" "}
                <br />
                <br />
              </Text>
            </div>
            <div className="avoid-break">
              <Text strong>
                Steuer &amp; Registereintrag <br />
              </Text>
              <Text>
                Ust-IDNr. DE 340 985 477 <br />
                Steuer-Nr. 162/107/06880 <br />
                HRB 508202 <br />
                Amtsgericht Jena <br />
                <br />
              </Text>
            </div>
            <div className="avoid-break">
              <Text strong>
                Datenschutzbeauftragter: <br />
              </Text>
              <Text>
                Kai-Uwe Marquardt <br />
                Telefon:{" "}
                <a href="tel: 49123456789" rel="noopener noreferrer">
                  01511 6261369
                </a>
                <br />
                E-Mail:{" "}
                <a href="mailto:datenschutz-dtb@digitale-therapiebegleitung.de">
                  datenschutz-dtb@digitale-therapiebegleitung.de
                </a>{" "}
                <br />
                <br />
              </Text>
            </div>
            <div className="avoid-break">
              <Text strong>
                Compliance-Officer: <br />
              </Text>
              <Text>
                Frank Sürmann <br />
                Telefon:{" "}
                <a href="tel: 49123456789" rel="noopener noreferrer">
                  0621 1250860
                </a>
                <br />
                E-Mail:{" "}
                <a href="mailto:compliance@convales.de">
                  compliance@convales.de
                </a>{" "}
                <br />
                <br />
              </Text>
            </div>
            <div className="avoid-break">
              <Text strong>
                Zweckbestimmung
                <br />
              </Text>
              <Text>
                Der APAT DTB Proto ist ein digitaler Therapiebegleiter zur
                aktiven Unterstützung des Therapiemanagements von Patienten mit
                einer ambulanten parenteralen Antiinfektivatherapie (APAT).
                <br />
                <br />
              </Text>

              <Text>
                Der APAT DTB Proto verbessert das Therapiemanagement durch:
                <br />
                <ul>
                  <li>
                    applikationszeitpunkt- und medikationsbezogene
                    Therapiepläne,
                  </li>
                  <li>
                    therapieindividuelle Erinnerungsfunktionen zur Applikation,
                  </li>
                  <li>
                    therapieindividuelle Anzeige von Nebenwirkungen,
                    Vitalwerten, Anwendungs- und Einnahmehinweise sowie wichtige
                    Informationen zur Medikation,
                  </li>
                  <li>
                    die Dokumentation von Applikationen, Nebenwirkungen und
                    Vitalwerten.
                  </li>
                </ul>
                <br />
              </Text>
            </div>
          </Col>
        </Col>
      </Row>
    </div>
  );
};

export default Imprint;
