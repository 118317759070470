import { createContext } from "react";
import { configure } from "mobx";

import { AuthStore } from "./AuthStore/AuthStore";
import { MedicationTherapyStore } from "./MedicationTherapyStore/MedicationTherapyStore";
import { MessagingStore } from "./MessagingStore/MessagingStore";
import { PatientCareStore } from "./PatientCareStore/PatientCareStore";
import { TherapyStore } from "./TherapyStore/TherapyStore";
import { UIStore } from "./UIStore";
import { HcpStore } from "./HcpStore/HcpStore";
import { OrganisationManagementStore } from "./organisationManagementStore/organisationManagementStore";

configure({
  enforceActions: "never",
});

/**
 * @description create mobx stores
 */
export const rootStoreContext = createContext({
  uiStore: new UIStore(),
  authStore: new AuthStore(),
  organisationManagementStore: new OrganisationManagementStore(),
  patientCareStore: new PatientCareStore(),
  messagingStore: new MessagingStore(),
  therapyStore: new TherapyStore(),
  medicationTherapyStore: new MedicationTherapyStore(),
  hcpStore: new HcpStore(),
});
