const config = {
  keycloak: {
    clientId: "hub-connect",
    realm: "diga-lab-hub",
    url: "https://sso.apat-dtb.com/auth",
  },
  io: {
    url: "https://medipolis.socket.api.apat-dtb.com",
    path: "/socket.io",
  },
  jitsimeet: {
    domain: "meet.apat-dtb.com",
    libraryName: "external_api.min.js",
    config: {},
  },
  patientManagement: {
    url: "https://pm-doc.apat-dtb.com",
  },
  medicationTherapyManagement: {
    url: "https://medication-doc.apat-dtb.com",
  },
  therapyDiagnoses: {
    url: "https://therapy-api.apat-dtb.com",
  },
  dtbplaner: {
    url: "https://therapieplaner.apat-dtb.com",
  },
  process: {
    url: "https://process-api.apat-dtb.com",
  },
  pusher: {
    channels: {
      appId: "1363788",
      key: "f74bb9dcab6361797975",
      cluster: "eu",
    },
  },
  meet: {
    url: "https://meet-development.tino-dtb.com",
  },
};

export default config;
