import React from "react";
import { MenuProps } from "antd";
import { Menu } from "antd";
import { UnorderedListOutlined, DeleteOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

/**
 * ---
 * Sider
 * ---
 * @class core.components/Sider
 * @property {Array} menuItems list of menu items
 * @type {React.FC<any>}
 * @return {React.ReactElement}
 */
const Sider = ({ theme = "dark" }: any) => {
  const [current, setCurrent] = React.useState("1");
  const { t } = useTranslation();

  /**
   * @method core.components/Sider.onClick
   * @param {any} e onlick event handler props
   */
  const onClick: MenuProps["onClick"] = (e): void => {
    setCurrent(e.key);
  };

  const menuItems = [
    {
      key: "1",
      label: <Link to="/cases">{t("coreNav:treatment")}</Link>,
      icon: <UnorderedListOutlined />,
    },
    {
      key: "2",
      label: <Link to="/archive">{t("coreNav:archive")}</Link>,
      icon: <DeleteOutlined />,
    },
  ];

  return (
    <Menu
      theme={theme}
      onClick={onClick}
      style={{
        width: 200,
        height: "auto",
        display: "block",
      }}
      defaultOpenKeys={["sub1"]}
      selectedKeys={[current]}
      mode="inline"
      items={menuItems}
    />
  );
};

export default Sider;
