import React, {
  ChangeEvent,
  EventHandler,
  FC,
  MouseEventHandler,
  useEffect,
  useState,
} from "react";
import { inject, observer } from "mobx-react";
import CasesTable from "../../components/Tables/CasesTable/CasesTable";
import { Layout } from "antd";
import { ICasesOverview } from "../../models/pages/ICasesOverview";
import "./../../scss/pages/CasesOverview.scss";
import CasesHeader from "../../components/CasesHeader/CasesHeader";
import config from "../../../config";
import { useNavigate } from "react-router-dom";

const { Content } = Layout;

const CasesOverview: FC<ICasesOverview> = ({
  patientCareStore,
  medicationTherapyStore,
  authStore,
  organisationManagementStore,
}): JSX.Element => {
  const [searchFilter, setSearchFilter] = useState<string>("");
  const orgConfig = organisationManagementStore.getOrgConfig;
  const userAttributes = authStore.getUserAttributes;
  const casesLoaded: boolean = patientCareStore.getCasesLoaded;
  const navigate = useNavigate();

  useEffect(() => {
    !casesLoaded && getCases();
    return () => {
      patientCareStore.setCasesLoading(false);
      patientCareStore.setCases([]);
      patientCareStore.setAllCasesLoaded(false);
    };
  }, [patientCareStore]);

  const getCases = async (): Promise<any> => {
    await patientCareStore.fetchCases();
  };

  const onchangeFilter: EventHandler<ChangeEvent> = (
    e: ChangeEvent<HTMLSelectElement>
  ): void => {
    setSearchFilter(e.target.value);
  };

  /**
   * @description invite patient
   */
  const invitePatient: MouseEventHandler<HTMLElement> = (): void => {
    window.location.replace(config.dtbplaner.url);
  };

  /**
   *
   * @description search value
   * @param searchVal
   */
  const search: EventHandler<any> = (searchVal: string): void => {
    if (searchVal.length > 0) {
      patientCareStore.allCasesLoaded = true;
    } else {
      patientCareStore.allCasesLoaded = false;
      patientCareStore.casesLoadedMoreSkip = 1;
    }
    patientCareStore.fetchCases(searchVal);
  };

  /**
   *
   * @description navigate to link
   * @param param0
   */
  const navigateToLink = ({
    caseId,
    patientId,
  }: {
    caseId: string;
    patientId: string;
  }): void => {
    navigate(`/patient/${patientId}/case/${caseId}/monitoring`);
  };

  return (
    <Layout>
      <Content
        className=" about-wrapper "
        style={{
          padding: "0px 0px",
          margin: 0,
          marginBottom: "14px",
          flexDirection: "column",
        }}
      >
        <CasesHeader
          search={search}
          onchangeFilter={onchangeFilter}
          searchFilter={searchFilter}
          invitePatient={invitePatient}
          userAttributes={userAttributes}
          orgConfig={orgConfig}
          casesLoaded={casesLoaded}
        />
        <CasesTable
          patientCareStore={patientCareStore}
          medicationTherapyStore={medicationTherapyStore}
          navigateToLink={navigateToLink}
        />
      </Content>
    </Layout>
  );
};

export default inject(
  "patientCareStore",
  "medicationTherapyStore",
  "authStore",
  "organisationManagementStore"
)(observer(CasesOverview));
